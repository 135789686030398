.MuiTableCell-root{
    position: relative;
}

.table-item {
    position: absolute !important;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
